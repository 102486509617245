import Vue from 'vue'
import { getAuth, onAuthStateChanged } from '@firebase/auth'
import { ToastProgrammatic as Toast } from 'buefy';
import { doc, setDoc, collection, query, where, getFirestore, Timestamp, getDoc, getDocs, orderBy, limit, startAfter } from "firebase/firestore";
import { getRemoteConfigValue } from './remoteConfig';

export function loadOrders(payload) {
    const app = Vue.prototype.$firebaseApp
    const auth = getAuth(app)
    const db = getFirestore(app)

    return new Promise((resolve, reject) => {
        onAuthStateChanged(auth, async (user) => {
            if (user) {

                var q = query(collection(db, "orders"), where("uid", "==", Number(user.uid)), orderBy("created", "desc"), limit(payload.pageSize))

                if (payload.lastQuery != null) {
                    q = query(collection(db, "orders"), where("uid", "==", Number(user.uid)), orderBy("created", "desc"), limit(payload.pageSize), startAfter(payload.lastQuery))
                }

                const snap = await getDocs(q);
                const response = snap.docs.map(doc => { return { ...doc.data(), id: doc.id } })
                const lastQuery = snap.docs[snap.docs.length - 1]

                resolve({ response, lastQuery })

            } else {
                reject();
            }
        });
    })
}

export function saveOrders(payload) {
    const app = Vue.prototype.$firebaseApp
    const auth = getAuth(app)
    const db = getFirestore(app)

    return new Promise((resolve, reject) => {
        onAuthStateChanged(auth, async (user) => {
            if (user) {

                const cartRef = doc(db, "cart", user.uid);
                const data = {
                    uid: user.uid,
                    items: payload,
                    updated: Timestamp.now()
                }

                setDoc(cartRef, data, { merge: false })

                resolve();
            } else {
                reject();
            }
        });
    });
}

export function loadEvents(payload) {
    const app = Vue.prototype.$firebaseApp
    const db = getFirestore(app)

    return new Promise(async (resolve, reject) => {
        var q = query(collection(db, "events"), orderBy("start", "desc"), limit(payload.pageSize))

        if (payload.lastQuery != null) {
            q = query(collection(db, "events"), orderBy("start", "desc"), limit(payload.pageSize), startAfter(payload.lastQuery))
        }

        const snap = await getDocs(q);
        const response = snap.docs.map(doc => { return { ...doc.data(), id: doc.id } })
        const lastQuery = snap.docs[snap.docs.length - 1]

        resolve({ response, lastQuery })
    });
}

export function loadEvent(payload) {
    const app = Vue.prototype.$firebaseApp
    const db = getFirestore(app)

    return new Promise(async (resolve, reject) => {
        const eventRef = doc(db, "events", payload);
        const eventDoc = await getDoc(eventRef);

        if (!eventDoc.exists()) {
            reject(new Error('Could not find event, please try a different one.'));
            return;
        }


        const eventDocData = eventDoc.data();

        try {

            const apiURL = await getRemoteConfigValue('APIurl');

            var object = {}

            const headers = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "csrf-token": Vue.$cookies.get('XSRF-TOKEN'),
                }
            }

            const itemData = await fetch(`${apiURL.asString()}/api/products/${eventDocData.square_item_id}/get`, headers);
            const itemJsonData = await itemData.json();

            object = {
                item_data: {
                    ...itemJsonData.object,
                }
            }


        } catch (error) {
            console.log(error)

            object = {
                error: {
                    message: error.message,
                    code: error.code,
                    disable: true
                }
            };

            errorToast('Failed to retrieve ticket data.')
        }


        const eventData = {
            ...eventDocData,
            square: {
                ...object,
            },
            id: eventDoc.id
        }

        resolve(eventData)
    });
}


function errorToast(message) {
    Toast.open({
        message: message,
        type: "is-danger",
        position: "is-bottom",
        duration: 5000
    });
}