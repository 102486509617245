<style>
#map {
  position: relative;
  overflow: auto;
  width: 100%;
  height: 130px;
  border-radius: 10px;
  margin-top: 10px;
}

.description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
}

.description.more {
  -webkit-line-clamp: unset;
  overflow: visible;
}
</style>
<template>
  <div>
    <div class="row mb-2">
      <div class="col">
        <button class="btn" :class="darkModeBtn" @click="$router.push('/events')">Back</button>
      </div>
    </div>

    <b-notification v-if="event != null && dateBeen(event.start.toDate())" class="p-2 mt-2 mb-2" type="is-warning" :closable="false" has-icon role="alert">This event has already happened, please select a different event.</b-notification>

    <div v-if="event != null" class="card p-4" :class="darkMode">
      <div class="row">
        <div class="col">
          <main-title :noMargin="true" tag="h4" customClass="mb-1" :text="event.name"></main-title>
          <h6>{{ event.start.toDate().toLocaleDateString( 'en-gb', { year: 'numeric', month: 'long', day: 'numeric' }) }}</h6>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <p class="text-gray m-0 description" v-bind:class="[descriptionLength ? 'more' : '']">{{ event.description }}</p>
          <a href="#" v-on:click="descriptionLength = !descriptionLength">{{ descriptionLength ? 'Show less' : 'Show more' }}</a>
        </div>
      </div>

      <!-- Event Time -->
      <div class="row mt-4">
        <div class="col">
          <div class="row">
            <div class="col">
              <h5 class="m-0">Event Time</h5>
            </div>

            <div class="col-2 d-flex justify-content-end">
              <i class="fas fa-calendar p-1"></i>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <p class="m-0">
                {{ event.start.toDate().toLocaleTimeString( 'en-gb', { hour: 'numeric', minute: 'numeric', hour12: true }) }} - {{ event.end.toDate().toLocaleTimeString( 'en-gb', { hour: 'numeric', minute: 'numeric', hour12: true }) }}
                <!-- <small
                  style="font-size: 11px; position: absolute; margin-left: 5px;"
                  v-if="!sameDay(event.start.toDate(), event.end.toDate())"
                >(Next Day)</small>-->
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- End Event Time -->

      <!-- Event Location -->
      <div class="row mt-4">
        <div class="col">
          <div class="row">
            <div class="col">
              <h5 class="m-0">Location</h5>
            </div>

            <div class="col-2 d-flex justify-content-end">
              <i class="fas fa-map-pin p-1"></i>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <template v-if="!mapsEnabled">
                <a class="btn btn-primary mt-2" target="_blank" :href="`http://maps.google.com/maps?q=${event.location.latitude},${event.location.longitude}`">Open in Google Maps</a>
              </template>

              <template v-if="mapsEnabled">
                <a target="_blank" :href="`http://maps.google.com/maps?q=${event.location.latitude},${event.location.longitude}`" class="m-0 mt-1">{{ address ? address : 'Click to open in Google Maps' }}</a>
                <div id="map" :class="[mapsLoading ? 'd-none' : '']"></div>
                <content-loader v-if="mapsLoading" style="margin-top: 10px;" :width="311" :height="130" :speed="2" primaryColor="#b0b0b0" secondaryColor="#ecebeb">
                  <rect x="0" y="0" rx="5" ry="5" width="311" height="130" />
                </content-loader>
              </template>
            </div>
          </div>
        </div>
      </div>
      <!-- End Event Location -->

      <!-- Event Tickets -->
      <div class="row mt-4">
        <div class="col">
          <div class="row">
            <div class="col">
              <h5 class="m-0">Tickets</h5>
              <p class="m-0" style="font-size: 12px;">Tap on the ticket below to buy it, tickets listed at £0 will not cost any money.</p>
            </div>

            <div class="col-2 d-flex justify-content-end">
              <i class="fas fa-ticket-alt p-1"></i>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <!-- <ticket-view :name="'test'" :description="'test'" :date="new Date()" :price="100"></ticket-view> -->

              <!-- Ticket -->
              <template v-for="ticket in event.square.item_data.item_data.variations">
                <div :key="ticket.id">
                  <ticket-view
                    :id="ticket.id"
                    :url="`/events/pay/${ticket.id}`"
                    :name="ticket.item_variation_data.name"
                    :description="event.square.item_data.item_data.description"
                    :date="event.start.toDate()"
                    :price="ticket.item_variation_data.price_money.amount"
                  ></ticket-view>
                </div>
              </template>
              <!-- End Ticket -->
            </div>
          </div>
        </div>
      </div>
      <!-- End Event Tickets -->
    </div>
    <template v-else>
      <div class="w-100 d-flex justify-content-center align-items-center p-4">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { ContentLoader } from "vue-content-loader";
import { darkModeStyle } from '@assets/js/darkMode';
import { loadEvent } from '@assets/js/dbController';
import { Loader } from '@googlemaps/js-api-loader';
import { getRemoteConfigValue } from '@assets/js/remoteConfig';

export default {
  components: {
    ContentLoader,
  },
  data() {
    return {
      event: null,
      address: null,
      mapsLoading: false,
      mapsEnabled: true,
      descriptionLength: false,
      ticketDisabled: [],
      darkMode: darkModeStyle(),
      darkModeText: darkModeStyle('text-light', 'text-dark'),
      darkModeBtn: darkModeStyle('btn-dark', 'btn-light'),
      darkModeCal: darkModeStyle('date-list-item_light', 'date-list-item_dark'),
    }
  },
  methods: {
    async initPage() {

      try {

        const eventData = await loadEvent(this.$route.params.id);

        const googleMapsEnabled = await getRemoteConfigValue('googleMapsEnabled');
        const googleMapsAPIKey = await getRemoteConfigValue('googleMapsAPIKey');

        this.event = eventData;

        if (googleMapsEnabled.asBoolean()) {

          const position = {
            lat: eventData.location.latitude,
            lng: eventData.location.longitude
          };

          const LOADER = new Loader({
            apiKey: googleMapsAPIKey.asString(),
            version: "weekly",
            libraries: ["places"]
          });

          LOADER.load().then(async (google) => {
            this.mapsLoading = true;

            const map = await this.getMaps(google, position);
            const address = await this.getAddress(google, position)

            this.address = address;
            this.mapsLoading = false;
          });

        } else {
          this.mapsEnabled = false;
        }

      } catch (e) {

        this.$buefy.toast.open({
          message: e.message,
          type: 'is-danger',
          queue: false,
          duration: 5000
        });

        this.$router.push('/events');
        return;
      }

    },
    async getMaps(google, position) {

      const mapOptions = { center: { ...position }, zoom: 17, mapTypeId: "satellite", disableDefaultUI: true };
      const map = new google.maps.Map(document.getElementById("map"), mapOptions);
      new google.maps.Marker({ position: { ...position }, map: map });

      return map;
    },
    async getAddress(google, position) {

      const geocoder = new google.maps.Geocoder();
      const latlng = new google.maps.LatLng(position.lat, position.lng);

      return new Promise((resolve, reject) => {
        geocoder.geocode({ 'location': latlng }, function(results, status) {
          if (status === 'OK') {
            if (results[0]) {
              resolve(results[0].formatted_address);
            } else {
              reject('No results found');
            }
          } else {
            reject('Geocoder failed due to: ' + status);
          }
        });
      });
    },
    sameDay(date1, date2) {
      return date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate();
    },
    dateBeen(date) {
      return date.getTime() < new Date().getTime();
    },
  },
  async mounted() {
    this.initPage();
  }
}
</script>